$( document ).ready(function() {

	var windowSize;
	windowSize = window.innerWidth;

	// Get window width on resie
	$( window ).resize(function() {
		windowSize = window.innerWidth;

		if (windowSize > 1024){
			$('header nav').show();
			$('.sidebar').show();
		} else {
			$('header nav').hide();
			$('.sidebar').hide();
		}
	});


	// Toggle Menu
	$(".menu-expand").click(function(e) { 

		e.preventDefault();
		openMenu();	

	});


	// Toggle Menu Function
	function openMenu () {

		$siteNav2 = $('#utilityMenu');

		if ( $siteNav2.hasClass('close') ){
			$siteNav2.removeClass('close');
			$($siteNav2).slideToggle('500');
			$(".menu-expand").removeClass('opened');
		} else {
			$siteNav2.addClass('close');
			$($siteNav2).slideToggle('500');
			$(".menu-expand").addClass('opened');
		}
		
	}


	// Font size
	window.fontSize = function(size) {
		$('body').removeClass('fontM fontL fontXL').addClass(size);
		
		request = $.ajax({
			type: "POST",
			url: "helper/changeFontSize.php",
			data: { fontSize: size }
		});
	
		// callback handler that will be called on success
		request.done(function (response, textStatus, jqXHR){
			// log a message to the console
			console.log('Font changed.');
		});
	
		// callback handler that will be called on failure
		request.fail(function (jqXHR, textStatus, errorThrown){
			// log the error to the console
			console.error(
				"The following error occured: "+
				textStatus, errorThrown
			);
		});
    }


	// Nav
	$siteNav = $('.sidebar');
	$MenuBtn = $('.nav__dropdown');

	$MenuBtn.click(function (e) {

		if(window.innerWidth < 1025){
			if ( $siteNav.hasClass('close') ){
				$siteNav.removeClass('close');
				$($siteNav).slideToggle('500');
			} else {
				$siteNav.addClass('close');
				$($siteNav).slideToggle('500');
			}

			e.preventDefault();
		}
		
	});


    // Scroll To Top
    // Check to see if the window is top if not then display button
    $( document ).scroll(function(){
        if ($(this).scrollTop() > 300) {
            $('.scroll--top').fadeIn();
        } else {
            $('.scroll--top').fadeOut();
        }
    });

    // Click event to scroll to top
    $('.scroll--top').click(function(){
        $('html,body').animate({scrollTop : 0},800);
        return false;
	});
	

    // Accordions
	var acc = document.getElementsByClassName("accordion__header");
	var i;

	for (i = 0; i < acc.length; i++) {
		acc[i].addEventListener("click", function() {
			/* Toggle between adding and removing the "active" class,
			to highlight the button that controls the panel */
			this.classList.toggle("active");

			this.firstElementChild.classList.toggle('icon__toggledown');
			this.firstElementChild.classList.toggle('icon__toggleup');

			/* Toggle between hiding and showing the active panel */
			$(this).next().slideToggle('500', function() {
				// Animation complete
			});
		});
	}
	

    // Datatables Initialize
	var tableTarget = 'table.dataTable:not("#example")';
	var tableMobileTarget = 'table.dataTable:not("#example")';
	
	initDataTable(tableTarget);
	initMobileDataTable(tableMobileTarget);

	// Remove alerts
	$(document).on('click', '.alert__close', function(){
		$( this ).closest('.alert').remove();
	});

	// Modal
	// Get the modal
	var modal = document.getElementById('myModal');

	// Get the button that opens the modal
	var btn = document.getElementById("myBtn");

	// Get the <span> element that closes the modal
	var span = document.getElementsByClassName("modal__close")[0];

	// When the user clicks the button, open the modal
	if ( btn != null || modal != null || span != null ) {
		btn.onclick = function() {
			modal.style.display = "block";
		}
	
		// When the user clicks on <span> (x), close the modal
		span.onclick = function() {
			modal.style.display = "none";
		}
	
		// When the user clicks anywhere outside of the modal, close it
		window.onclick = function(event) {
			if (event.target == modal) {
				modal.style.display = "none";
			}
		}
	}

	// init datepicker
	$('[data-toggle="datepicker"]').datepicker(
		'setDate', new Date()
	);

	//If anchor in URL, scroll to
	var urlHash = window.location.href.split("#")[1];
	if(urlHash !== undefined && $('#' + urlHash).length){

		$('html,body').animate({ scrollTop : $('#' + urlHash).offset().top },800);

	}

});

// Site Style
function siteStyle(obj) {

	request = $.ajax({
		type: "POST",
		url: "helper/changeTheme.php",
		data: { theme: obj }
	});

	// callback handler that will be called on success
	request.done(function (response, textStatus, jqXHR){
		// log a message to the console
		console.log('Theme changed.');
	});

	// callback handler that will be called on failure
	request.fail(function (jqXHR, textStatus, errorThrown){
		// log the error to the console
		console.error(
			"The following error occured: "+
			textStatus, errorThrown
		);
	});

	location.reload();

}

// Tabs
function showTab(evt, tab_num) {
    // Declare all variables
    var i, tabcontent, tablinks;

    // Get all elements with class="tabcontent" and hide them
    tabcontent = document.getElementsByClassName("tab__content");
    for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
    }

    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName("tab__button");
    for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
    }

    // Show the current tab, and add an "active" class to the button that opened the tab
    document.getElementById(tab_num).style.display = "block";
    evt.currentTarget.className += " active";
}

// Datatables functions
function initDataTable(tableTarget) {
	if ($(tableTarget).hasClass('full-entry-check')) {
		$(tableTarget).dataTable({
			"pageLength": 25,
			"columnDefs": [{
				"targets": 0,
				"orderable": false
			}],
			"order": [[ 1, "asc" ]],
			"language": {
				"lengthMenu": "Show: _MENU_",
				"zeroRecords": "Nothing found - sorry",
				"info": "Showing _START_ to _END_ of _TOTAL_",
				"infoEmpty": "No records available",
				"infoFiltered": "(filtered from _MAX_ total records)"
			},
			"dom": '<"top"lf>rt<"bottom"ip><"clear">',
			"initComplete": function(settings, json) {
				$('.dataTables_wrapper, .dataTable').fadeIn( "slow", function() {
					// Animation complete
				});
			  }
		});
	} else {
		$(tableTarget).dataTable({
			"pageLength": 25,
			"pagingType": "full_numbers",
			"language": {
				"lengthMenu": "Show: _MENU_",
				"zeroRecords": "Nothing found - sorry",
				"info": "Showing _START_ to _END_ of _TOTAL_",
				"infoEmpty": "No records available",
				"infoFiltered": "(filtered from _MAX_ total records)"
			},
			"dom": '<"top"lf>rt<"bottom"ip><"clear">',
			"initComplete": function(settings, json) {
				$('.dataTables_wrapper, .dataTable').fadeIn( "slow", function() {
					// Animation complete
				});
			}
		});
	}
}

// Create responsive data table layout for mobile
function initMobileDataTable(tableMobileTarget) {
	var numberDataTables = $(tableMobileTarget).length;

	if (numberDataTables > 0){
		if(numberDataTables ==1){
				if(!$(tableMobileTarget).hasClass('styles-added')) {
					$( tableMobileTarget + ' th' ).each(function( index, value ) {
						var content = $(value)[0].innerText;
						$(tableMobileTarget).addClass('styles-added');
						$('<style>' +tableMobileTarget+ ' td:nth-of-type('+(index+1)+'):before{content:"'+content+'"}</style>').appendTo(tableMobileTarget);
					});
				}															
				
		} else {
			$( tableMobileTarget).each(function( i, e ) { 
				var tableNumber = i;
				if(!$(e).hasClass('styles-added')) {															
					$( $(this).find('th') ).each(function( index, value ) {
						var content = $(value)[0].innerText;
						$(e).addClass('styles-added');
						$('<style>' +tableMobileTarget+ ':nth-child('+(tableNumber+1)+') td:nth-of-type('+(index+1)+'):before{content:"'+content+'"}</style>').appendTo(e);
					});
				}
			});
		}
	}
}


// Return Home Function
function returnHome() {

	window.location.href = document.location.origin;

}


// Full page loader
function showLoader(){
	$('.loader--full').show();
}

function hideLoader(){
	$('.loader--full').hide();
}
